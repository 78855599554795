import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMsg } from './../../components/Components';

export default function Form({
	handleCloseModal,
	onSubmit,
	defaultValues,
	formID,
	facilities,
}) {
	const { register, errors, handleSubmit } = useForm({
		defaultValues,
	});

	return (
		<>
			<form id={formID} onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					<div className='row'>
						<div className='form-group col-md-6'>
							<label>First Name</label>
							<input
								type='text'
								className='form-control'
								name='first_name'
								placeholder='First Name'
								defaultValue={defaultValues.first_name}
								ref={register({
									required: 'First name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='first_name' />
						</div>

						<div className='form-group col-md-6'>
							<label>Last Name</label>
							<input
								type='text'
								className='form-control'
								name='last_name'
								placeholder='Last Name'
								defaultValue={defaultValues.last_name}
								ref={register({
									required: 'Last name is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='last_name' />
						</div>

						<div className='form-group col-md-6'>
							<label>Email Address</label>
							<input
								type='text'
								className='form-control'
								name='email'
								placeholder='Email Address'
								defaultValue={defaultValues.email}
								ref={register({
									required: 'Email address is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='email' />
						</div>

						<div className='form-group col-md-6'>
							<label>Phone Number</label>
							<input
								type='number'
								className='form-control'
								name='phone'
								placeholder='Phone Number'
								defaultValue={defaultValues.phone}
								ref={register({
									required: 'Phone number is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='phone' />
						</div>

						<div className='form-group col-md-6'>
							<label>Graduation School</label>
							<input
								type='text'
								className='form-control'
								name='graduation_school'
								placeholder='Graduation School'
								defaultValue={defaultValues.graduation_school}
								ref={register({
									required: 'Graduation school is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='graduation_school' />
						</div>

						<div className='form-group col-md-6'>
							<label>Graduation Year</label>
							<input
								type='text'
								className='form-control'
								name='graduation_year'
								placeholder='Graduation Year'
								defaultValue={defaultValues.graduation_year}
								ref={register({
									required: 'Graduation Year is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='graduation_year' />
						</div>

						<div className='form-group col-md-6'>
							<label>Current License Year</label>
							<input
								type='text'
								className='form-control'
								name='current_license_year'
								placeholder='Current License Year'
								defaultValue={defaultValues.current_license_year}
								ref={register({
									required: 'Current License Year is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='current_license_year' />
						</div>

						<div className='form-group col-md-6'>
							<label>State</label>
							<input
								type='text'
								className='form-control'
								name='state'
								placeholder='State'
								defaultValue={defaultValues.state}
								ref={register({
									required: 'State is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='state' />
						</div>

						<div className='form-group col-md-6'>
							<label>LGA</label>
							<input
								type='text'
								className='form-control'
								name='lga'
								placeholder='LGA'
								defaultValue={defaultValues.lga}
								ref={register({
									required: 'LGA is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='lga' />
						</div>

						<div className='form-group col-md-6'>
							<label>Address</label>
							<input
								className='form-control'
								name='address'
								ref={register({
									required: 'Address is required!',
								})}
								defaultValue={defaultValues.address}
								placeholder='Address'
							/>
							<ErrorMsg errors={errors} name='address' />
						</div>

						<div className='form-group col-md-6'>
							<label>Specialty</label>
							<input
								type='text'
								className='form-control'
								name='specialty'
								placeholder='Specialty'
								defaultValue={defaultValues.specialty}
								ref={register({
									required: 'Specialty is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='specialty' />
						</div>

						<div className='form-group col-md-6'>
							<label>Extra Proficiency</label>
							<input
								type='text'
								className='form-control'
								name='extra_proficiency'
								placeholder='Extra Proficiency'
								defaultValue={defaultValues.extra_proficiency}
								ref={register({
									required: 'Extra Proficiency is required!',
								})}
							/>
							<ErrorMsg errors={errors} name='extra_proficiency' />
						</div>

						<div className='form-group col-md-12'>
							<label>Facility</label>
							<select
								className='form-control'
								name='facility_id'
								defaultValue={defaultValues.facility_id}
								ref={register()}
							>
								<option value=''> - Select -</option>
								{facilities &&
									facilities.length > 0 &&
									facilities.map((row, index) => {
										return (
											<option key={index} value={row.id}>
												{row.name}
											</option>
										);
									})}
							</select>
							<ErrorMsg errors={errors} name='facility_id' />
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<input
						type='button'
						onClick={handleCloseModal}
						className='btn btn-default'
						value='Cancel'
					/>
					<button className='btn btn-success btn-main' type='submit'>
						Save
					</button>
				</Modal.Footer>
			</form>
		</>
	);
}
