/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../context';
import {
	adminUserCreate,
	adminUserUpdate,
	adminUserDelete,
	adminUsers,
	facilitiesList,
} from '../../Services';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import Form from './form';
import useModal from './../../hooks/useModal';
import useFormState from './../../hooks/useFormState';
import { Paginate } from './../../components/Components';

const initValues = {
	role_id: 9,
};

export default function Doctors({ history }) {
	const queryClient = useQueryClient();

	const [queryName] = useState('doctors');
	const [formID] = useState('form-doctor');
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const { toggleFormState } = useFormState(formID);

	const {
		dispatch,
		setQueryString,
		useQueryString,
		setStateData,
		currentPath,
		formActionType,
		errorResponse,
		formatDate,
		notify,
	} = useContext(AppContext);
	let queryString = useQueryString();
	const [defaultValues, setDefaultValues] = useState(initValues);

	const [query, setQuery] = useState({
		page:
			queryString.get('page') && !isNaN(queryString.get('page'))
				? Number(queryString.get('page'))
				: 1,
		role_id: 9,
	});

	const handleCloseModal = () => {
		closeModal(false);
		setDefaultValues(initValues);
	};

	/* Facilities. */
	const { data: facilities } = useQuery('listFacilities', facilitiesList, {
		onError: (error) => errorResponse({ error, history, dispatch }),
	});

	const formattedFacilities = facilities?.map((facility) => ({
		name: facility.name,
		id: facility.id,
	}));

	/* Page data */
	const { isLoading, data, error } = useQuery(
		[queryName, query],
		() => adminUsers(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Add data */
	const { mutate: handleSubmitAdd } = useMutation(
		(values) => adminUserCreate(values),
		{
			onSuccess: () => {
				handleCloseModal();
				notify(
					'success',
					'Doctor Account Added',
					'New doctor successfully added!'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Edit data */
	const { mutate: handleSubmitEdit } = useMutation(
		({ id, values }) => adminUserUpdate({ id, values }),
		{
			onSuccess: () => {
				handleCloseModal();
				notify(
					'success',
					'Doctor Account Modified',
					'Doctor successfully modified!'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
				toggleFormState(false);
			},
		}
	);

	/* Delete data */
	const { mutate: handleSubmitDelete } = useMutation(
		(id) => adminUserDelete(id),
		{
			onSuccess: () => {
				notify(
					'success',
					'Doctor Account Deleted',
					'Doctor successfully deleted!'
				);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
				const previousData = queryClient.getQueryData([queryName, query]);
				const updateValue = previousData?.data;

				const removeDeleted = updateValue.filter((dt) => dt.id !== id);
				const newData = { ...previousData, data: removeDeleted };
				return queryClient.setQueryData([queryName, query], newData);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => {
				queryClient.refetchQueries([queryName, query]);
			},
		}
	);

	/* Requery on data, query change */
	useEffect(() => {
		if (data?.next_page_url) {
			let nextPage = { ...query, page: query.page + 1 };
			queryClient.prefetchQuery([queryName, nextPage], () =>
				adminUsers(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const initAdd = () => {
		setStateData(dispatch, 'formActionType', 1);
		setModalTitle('New Doctor');
		setModalState(true);
	};

	const initEdit = (data) => {
		setStateData(dispatch, 'formActionType', 2);
		setModalTitle('Edit Doctor');
		setDefaultValues(data);
		setModalState(true);
	};

	const onSubmit = (data) => {
		const newData = { ...data, role_id: 9 };

		if (formActionType === 1) {
			handleSubmitAdd(newData);
		} else {
			handleSubmitEdit({
				id: defaultValues.id,
				values: newData,
			});
		}
	};

	const initDelete = (id) => {
		const conf = window.confirm('Are you sure?');
		if (!conf) return;
		handleSubmitDelete(id);
	};

	return (
		<>
			{isLoading && <div>loading...</div>}
			{!isLoading && error && <div>error: {error.message}...</div>}

			{data && data?.data?.length > 0 && (
				<div className='row'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body'>
								<div className='row mb-2'>
									<div className='col float-right'>
										<div className='text-sm-right'>
											<button
												type='button'
												onClick={initAdd}
												className='btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2'
											>
												<i className='mdi mdi-plus mr-1'></i> New Doctor
											</button>
										</div>
									</div>
								</div>

								<div className='table-responsive'>
									<table className='table table-centered table-striped table-nowrap'>
										<thead>
											<tr>
												<th>Details</th>
												<th>Specialty</th>
												<th className='text-center'>Status</th>
												<th className='text-right'>Created At</th>
												<th className='text-center'>Actions</th>
											</tr>
										</thead>
										<tbody>
											{data?.data?.map((row) => {
												return (
													<tr key={row.id}>
														<td>
															<b>
																{row.first_name} {row.last_name}
															</b>
															<p className='mb-0'>{row.phone}</p>
															<p className='mb-0'>{row.email}</p>
														</td>
														<td>
															{row.specialty || '-'} /{' '}
															{row.extra_proficiency || '-'}
														</td>
														<td className='text-center'>
															<span
																className={
																	row.verified
																		? 'badge badge-success font-size-12'
																		: 'badge badge-danger font-size-12'
																}
															>
																{row.verified ? 'Active' : 'Inactive'}
															</span>
														</td>
														<td className='text-right'>
															{formatDate(row.created_at)}
														</td>
														<td width='1%' className='text-center'>
															<div className='dropdown'>
																<a
																	href='#'
																	className='dropdown-toggle card-drop'
																	data-toggle='dropdown'
																	aria-expanded='false'
																>
																	<i className='mdi mdi-dots-horizontal font-size-18'></i>
																</a>
																<ul className='dropdown-menu dropdown-menu-right'>
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initEdit(row)}
																			className='dropdown-item'
																		>
																			<i className='fas fa-pencil-alt text-success mr-1'></i>{' '}
																			Edit
																		</a>
																	</li>

																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			onClick={() => initDelete(row.id)}
																			className='dropdown-item'
																		>
																			<i className='fas fa-trash-alt text-danger mr-1'></i>{' '}
																			Delete
																		</a>
																	</li>

																	{row?.verified ? (
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					handleSubmitEdit({
																						id: row.id,
																						values: {
																							...row,
																							verified: 0,
																						},
																					})
																				}
																				className='dropdown-item'
																			>
																				<i className='fas fa-check-circle text-danger mr-1'></i>{' '}
																				Deactivate
																			</a>
																		</li>
																	) : (
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					handleSubmitEdit({
																						id: row.id,
																						values: { ...row, verified: 1 },
																					})
																				}
																				className='dropdown-item'
																			>
																				<i className='fas fa-check-circle text-success mr-1'></i>{' '}
																				Activate
																			</a>
																		</li>
																	)}
																</ul>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>

								<Paginate data={data} onPageChange={handlePageClick} />
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				keyboard={false}
				backdrop='static'
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>

				<Form
					handleCloseModal={handleCloseModal}
					defaultValues={defaultValues}
					onSubmit={onSubmit}
					formID={formID}
					facilities={formattedFacilities}
				/>
			</Modal>
		</>
	);
}
