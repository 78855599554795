/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import AppContext from '../../context';
import {
	enrollees,
	enrolleeExport,
	enrolleeData,
	enrolleeDeactivate,
	packagesList as selectPackages,
	// packagesListShallow as selectPackages,
	selectLgas,
	mdasList,
	enrolleeDelete,
	facilitiesList,
	totals,
	dependantVerification,
} from '../../Services';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Paginate, Currency } from './../../components/Components';
import { useLocation } from 'react-router-dom';

import { accessRoles } from '../../utils/utils';

import useModal from './../../hooks/useModal';
import useRouter from './../../hooks/useRouter';

import Details from './details';
import PlanDetails from './planDetails';
import InitiateFacilityChange from './initiateFacilityChange';
import ModalSearch from './search';

const roles = accessRoles('enrollment');

const initValues = {
	services: [],
	contact: {},
};

export default function Enrollees({ history }) {
	const queryClient = useQueryClient();

	const location = useLocation();

	const {
		dispatch,
		setQueryString,
		currentPath,
		formatDateBr,
		notify,
		errorResponse,
		formatDate,
		userData,
	} = React.useContext(AppContext);

	const [defaultValues, setDefaultValues] = React.useState(initValues);

	const [enrolleeDetails, setEnrolleeDetails] = React.useState(null);
	const [enrolleeID, setEnrolleeID] = React.useState(null);

	const [queryName] = React.useState('enrollees');

	const { modalState, setModalState } = useModal();
	const {
		modalState: planModalState,
		setModalState: setPlanModalState,
		modalTitle: planModalTitle,
		setModalTitle: setPlanModalTitle,
	} = useModal();

	const {
		modalState: facilityChangeModalState,
		setModalState: setFacilityChangeModalState,
	} = useModal();

	const {
		modalState: msState,
		modalTitle: msTitle,
		setModalTitle: setMsTitle,
		closeModal,
		showModal,
	} = useModal();

	const router = useRouter();
	const [query, setQuery] = React.useState(router.query);
	const [searchValues, setSearchValues] = React.useState({
		start_date: new Date(),
	});
	const [clearSearch, setClearSearch] = React.useState(false);
	const [meta, setMeta] = React.useState({});
	const [searchType, setSearchType] = React.useState(1);

	const [packages, setPackages] = React.useState([]);
	const [lgas, setLgas] = React.useState([]);
	const [mdas, setMdas] = React.useState([]);
	const [facilities, setFacilities] = React.useState([]);

	const { start_date, end_date } = router.query;

	/* export query */
	const [queryExport, setQueryExport] = React.useState({});
	const [exportData, setExportData] = React.useState([]);
	const [exporting, setExporting] = React.useState(false);
	const [exportReady, setExportReady] = React.useState(false);
	const [toggleCloseBtn, setToggleCloseBtn] = React.useState(true);

	React.useEffect(() => {
		const rQquery = router.query;
		setQuery(rQquery);
		let sss = rQquery;
		sss = {
			...sss,
			start_date: start_date ? moment(start_date).toDate() : '',
			end_date: end_date ? moment(end_date).toDate() : '',
		};
		setSearchValues(sss);

		const clearSearchState = Object.keys(rQquery).length > 0 ? true : false;
		setClearSearch(clearSearchState);

		/* Clean up */
		return () => setQuery({});
	}, [router.query]);

	/* Page data */
	const { isLoading, data, error } = useQuery(
		[queryName, query],
		() => enrollees(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	// Totals
	const { data: totalsData, isLoading: totalsLoading } = useQuery(
		'total-count',
		() => totals(setQueryString(query)),
		{
			keepPreviousData: true,
			staleTime: 5000,
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Export data */
	useQuery(
		['export-active-enrollees', queryExport],
		() => enrolleeExport(setQueryString(queryExport)),
		{
			retry: 0,
			manual: true,
			enabled: exporting,
			refetchInterval: 1000,
			onSuccess: ({ data, next_page_url }) => {
				//console.log(data, 'export data...');
				data.length > 0 && setExportData([...exportData, ...data]);

				if (next_page_url) {
					setQueryExport({ ...queryExport, page: queryExport.page + 1 });
				} else {
					setExporting(false);
					setExportReady(true);
					setQueryExport({});
				}
			},
			onError: (error) => errorResponse({ error, history, dispatch }),
		}
	);

	/* Enrollee Data */
	const { isLoading: enrolleeLoading } = useQuery(
		['enrollee-details', enrolleeID],
		() => enrolleeData(enrolleeID),
		{
			onError: () => setEnrolleeDetails(null),
			onSuccess: (data) => setEnrolleeDetails(data),
			enabled: enrolleeID !== null,
		}
	);

	/* Packages */
	useQuery('select-packages', selectPackages, {
		onError: (error) => setPackages([]),
		onSuccess: (data) => setPackages(data),
	});

	/* Local Govt. */
	useQuery('select-lgas', selectLgas, {
		onError: (error) => setLgas([]),
		onSuccess: (data) => setLgas(data),
	});

	/* MDAS. */
	useQuery('select-mdas', mdasList, {
		onError: (error) => setMdas([]),
		onSuccess: (data) => setMdas(data),
	});

	/* Facilities. */
	useQuery('select-facilities', facilitiesList, {
		onError: (error) => setFacilities([]),
		onSuccess: (data) => setFacilities(data),
	});

	const { mutate: dependantVerificationMutation } = useMutation(
		(values) => dependantVerification(values.id, values.status),
		{
			onSuccess: () => {
				notify(
					'success',
					'Dependant Status Updated',
					'Dependant Status Updated successfully'
				);

				queryClient.invalidateQueries([queryName, query]);
			},
			onError: (error) => errorResponse({ error, dispatch }),
		}
	);

	const handleCloseModal = () => {
		setModalState(false);
		setEnrolleeID(null);
		setEnrolleeDetails(null);
		setDefaultValues(initValues);
	};

	const handlePlanCloseModal = () => {
		setPlanModalState(false);
		setDefaultValues(initValues);
	};

	const handleFacilityChangeCloseModal = () => {
		setFacilityChangeModalState(false);
		setEnrolleeID(null);
		setEnrolleeDetails(null);
		setDefaultValues(initValues);
	};

	/* Requery on data, query change */
	React.useEffect(() => {
		if (data && data.total > 0) {
			const newMeta = { from: data?.from, to: data?.to, total: data?.total };
			setMeta(newMeta);
		}

		if (data?.next_page_url) {
			let nextPage = {
				...query,
				page: query?.page ? Number(query.page) + 1 : 2,
			};
			// console.log(nextPage, 'nextPage...');
			queryClient.prefetchQuery([queryName, nextPage], () =>
				enrollees(setQueryString(nextPage))
			);
		}
	}, [data, query, queryClient]);

	/* handle paginate data */
	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		let nQ = { ...query, page };
		setQuery(nQ);
		history.push(`${currentPath}${setQueryString(nQ)}`);
	};

	const { mutate: handleSubmitDelete } = useMutation(
		(id) => enrolleeDelete(id),
		{
			onSuccess: () => {
				notify(
					'success',
					'Enrollee Deleted',
					'Enrollee data successfully deleted!'
				);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
				const previousData = queryClient.getQueryData([queryName, query]);
				const updateValue = previousData?.data;

				const removeDeleted = updateValue.filter((dt) => dt.id !== id);
				const newData = { ...previousData, data: removeDeleted };
				return queryClient.setQueryData([queryName, query], newData);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const { mutate: handleDeactivate } = useMutation(
		(id) => enrolleeDeactivate(id),
		{
			onSuccess: () => {
				notify(
					'success',
					'Enrollee Deactivated',
					'Enrollee successfully deactivated!'
				);
			},
			onMutate: (id) => {
				queryClient.cancelQueries([queryName, query]);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => queryClient.refetchQueries([queryName, query]),
		}
	);

	const initDelete = (id) => {
		const conf = window.confirm(
			'This will delete all enrollee data. Are you sure?'
		);
		if (!conf) return;
		handleSubmitDelete(id);
	};

	const initDetails = () => {
		setModalState(true);
	};

	const initEdit = async (id) => {
		const data = await enrolleeData(id);

		history.push('/enrollee/create', {
			continueData: data,
			editMode: true,
		});
	};

	const initEditPlan = (row) => {
		queryClient.prefetchQuery(['enrollee-details', row.id], () =>
			enrolleeData(row.id)
		);

		const title = row?.insurance_id
			? `Enrollee ID #${row?.insurance_id}`
			: `${row?.first_name} ${row?.last_name}`;
		setEnrolleeDetails(row);
		setPlanModalTitle(title);
		setPlanModalState(true);
	};

	const initFacilityChange = async () => {
		setFacilityChangeModalState(true);
	};

	const initAddDependant = async (id) => {
		const data = await enrolleeData(id);
		const { facilities } = data;

		history.push('/enrollee/add-dependant', {
			enrolleeData: {
				...data,
				facilities:
					(facilities &&
						facilities.length > 0 &&
						facilities.map((dt) => ({ label: dt.name, value: dt.id }))) ||
					[],
			},
		});
	};

	const handleStatusDeactivate = (id) => {
		handleDeactivate(id);
	};

	const initSearch = (type) => {
		setSearchType(type);
		showModal();
		const title = type === 2 ? 'Export to Excel' : 'Search..';
		setMsTitle(title);
	};

	const onSearchSubmit = (values) => {
		history.push(`${currentPath}${setQueryString(values)}`);
		closeModal();
	};

	const onExportSubmit = (values) => {
		setToggleCloseBtn(false);
		setExportData([]);
		setQueryExport({ ...values, page: 1 });
		setExporting(true);
	};

	const resetExport = () => {
		closeModal();
		setToggleCloseBtn(true);
		setExportData([]);
		setQueryExport({});
		setExporting(false);
		setExportReady(false);
		history.push(currentPath);
	};

	const showSearchAndExport =
		facilities.length > 0 && packages.length > 0 && mdas.length > 0;

	if (isLoading || totalsLoading) return <div>Loading...</div>;

	return (
		<>
			{!isLoading && !totalsLoading && error && (
				<div>error: {error.message}...</div>
			)}

			{data?.data && (
				<div className='row'>
					<div className='col-12'>
						<div className='pb-10 btn-tabs row'>
							<div className='col-sm-4'>
								<div
									className='dash-card dash-card-info dash-icon-4'
									style={{ alignItems: 'baseline' }}
								>
									<div className='dci-title'>
										Total Enrollees
										<div className='dci-content'>
											{totalsData && totalsData.sub_totals.toLocaleString()}
										</div>
									</div>
								</div>
							</div>

							<div className='col-sm-4'>
								<div
									className='dash-card dash-card-info dash-icon-1'
									style={{ alignItems: 'baseline' }}
								>
									<div className='dci-title'>
										Active Enrollees
										<div className='dci-content'>
											{totalsData &&
												totalsData.active_enrollees.toLocaleString()}
										</div>
									</div>
								</div>
							</div>

							{meta &&
								Object.keys(meta).length !== 0 &&
								meta.total !== totalsData.active_enrollees && (
									<div className='col-sm-4'>
										<div
											className='dash-card dash-card-info dash-icon-5'
											style={{ alignItems: 'baseline' }}
										>
											<div className='dci-title'>
												Search Results
												<div className='dci-content'>
													{meta && meta.total.toLocaleString()}
												</div>
											</div>
										</div>
									</div>
								)}
						</div>
						<div className='card'>
							<div className='card-body'>
								<div className='page-action-bar'>
									<div>
										{showSearchAndExport && (
											<div
												className='btn-group mr-2'
												role='group'
												aria-label='Basic example'
											>
												<button
													type='button'
													onClick={() => initSearch(1)}
													className='btn btn-success btn-rounded waves-effect waves-light'
												>
													<i className='bx bx-search-alt search-icon font-size-16 align-middle'></i>{' '}
													Search
												</button>

												{clearSearch && (
													<button
														type='button'
														onClick={() => history.push(currentPath)}
														className='btn btn-success btn-rounded waves-effect waves-light'
													>
														Clear{' '}
														<i className='bx bx-x search-icon font-size-16 align-middle'></i>
													</button>
												)}
											</div>
										)}

										{showSearchAndExport && (
											<button
												type='button'
												onClick={() => initSearch(2)}
												className='btn btn-secondary btn-rounded waves-effect waves-light'
											>
												<i className='bx bx-download search-icon font-size-16 align-middle'></i>{' '}
												Export to Excel
											</button>
										)}
									</div>

									{roles.includes(userData.role.name) && (
										<div>
											<button
												type='button'
												onClick={() => history.push('/enrollee/create')}
												className='btn btn-success btn-rounded waves-effect waves-light'
											>
												<i className='mdi mdi-plus mr-1'></i>New Enrollee
											</button>
										</div>
									)}
								</div>

								{!isLoading && data?.data && data?.data?.length === 0 && (
									<div className='no-data-box'>No data found!</div>
								)}

								{data?.data?.length > 0 && (
									<div
										className='table-responsive'
										style={{
											minHeight: '350px',
										}}
									>
										<table className='table table-centered table-nowrap table-striped'>
											<thead>
												<tr>
													<th width='15%'>Enrollee ID</th>
													<th width='20%'>Info</th>
													<th width='19%'>Contact</th>
													<th width='25%'>
														{location.search.includes('is_dependant')
															? 'Status'
															: 'Plan'}
													</th>
													<th width='10%'>Platform</th>
													<th width='10%' className='text-right'>
														Created At
													</th>
													<th width='1%' className='text-center'>
														<i className='mdi mdi-dots-horizontal' />
													</th>
												</tr>
											</thead>
											<tbody>
												{data?.data?.map((row) => {
													let sub = null;

													if (
														typeof row?.active_subs !== 'undefined' &&
														row?.active_subs.length > 0
													) {
														sub = row.active_subs.slice(-1)[0];
														//console.log(row.id, sub, 'subs....');
													}

													//const sub = row?.subscriptions && row?.subscriptions.length > 0 && (row.subscriptions).pop();

													return (
														<tr key={row.id}>
															<td>{row.insurance_id}</td>
															<td>
																<div className=' font-weight-bold'>
																	{row.first_name} {row.middle_name}{' '}
																	{row.last_name}
																</div>
																{row.gender} | {row.occupation}
																{row?.source === 'equity' && (
																	<>
																		<br />
																		<span className='badge badge-info'>
																			Equity
																		</span>
																	</>
																)}
															</td>
															<td>
																{row.lga}
																<br />
																{row.phone}
																{row.email && (
																	<>
																		<br />
																		{row.email}
																	</>
																)}
															</td>
															{location.search.includes('is_dependant') ? (
																<td
																	className={`text-capitalize ${
																		row.dependant_verification_status ===
																		'verified'
																			? 'text-success'
																			: row.dependant_verification_status ===
																			  'partially verified'
																			? 'text-warning'
																			: 'text-danger'
																	}`}
																>
																	{row.dependant_verification_status ||
																		'unknown status'}
																</td>
															) : (
																<td>
																	{row?.benefactor_id != null ? (
																		<strong className='font-size-12 text-muted'>
																			Dependant
																		</strong>
																	) : row?.package ? (
																		<>
																			<div className='font-size-13 font-weight-medium'>
																				{row?.package?.name}
																			</div>
																			<Currency
																				value={
																					row?.active_subs[0]
																						?.is_family_plan === 1
																						? row?.package?.family_amount
																						: row?.package?.amount
																				}
																			/>

																			{sub && (
																				<>
																					<div className='font-size-12 font-weight-medium text-secondary'>
																						{formatDate(sub.start_date, 'll')} -{' '}
																						{formatDate(
																							sub.expiration_date,
																							'll'
																						)}
																					</div>

																					<span className='badge badge-info'>
																						{/* {sub.is_family_plan === 1
																						? 'Family Plan'
																						: 'Individual Plan'} */}
																						{row?.active_subs[0]
																							?.is_family_plan === 1
																							? 'Family Plan'
																							: 'Individual Plan'}
																					</span>
																				</>
																			)}
																		</>
																	) : (
																		<strong className='font-size-12 text-muted'>
																			None
																		</strong>
																	)}

																	{/* {row?.package ? (<>
                                                    <div className="font-size-13 font-weight-medium">{row?.package?.name}</div>
                                                    <Currency value={sub?.is_family_plan === 1 ? (row?.package?.family_amount) : (row?.package?.amount)} />
                                                    {sub && (<>
                                                        <div className="font-size-12 font-weight-medium text-secondary">
                                                            {formatDate(sub.start_date, 'll')} - {formatDate(sub.expiration_date, 'll')}
                                                        </div>
                                                        <span className="badge badge-info">
                                                            {sub.is_family_plan === 1 ? 'Family Plan' : 'Individual Plan'}
                                                        </span>
                                                    </>)}
                                                </>)
                                                    : row?.benefactor_id !== null ?
                                                        (<strong className="font-size-12 text-muted">Dependant</strong>)
                                                        : (<strong className="font-size-12 text-muted">None</strong>)} */}
																</td>
															)}
															<td className='font-weight-bold'>
																{row.platform}
															</td>
															<td className='text-right'>
																{formatDateBr(row.enrolled_at)}
															</td>
															<td width='1%' className='text-center'>
																<div className='dropdown'>
																	<a
																		href='#'
																		className='dropdown-toggle card-drop'
																		data-toggle='dropdown'
																		aria-expanded='false'
																	>
																		<i className='mdi mdi-dots-horizontal font-size-18'></i>
																	</a>
																	<ul className='dropdown-menu dropdown-menu-right'>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					setEnrolleeID(row.id);
																					initDetails();
																				}}
																				className='dropdown-item'
																			>
																				View Details
																			</a>
																		</li>
																		{roles.includes(userData.role.name) && (
																			<li>
																				<a
																					style={{ cursor: 'pointer' }}
																					onClick={() => {
																						initEdit(row.id);
																					}}
																					className='dropdown-item'
																				>
																					Edit Personal Info
																				</a>
																			</li>
																		)}
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					initEditPlan(row);
																				}}
																				className='dropdown-item'
																			>
																				Upgrade Plan
																			</a>
																		</li>
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() => {
																					setEnrolleeID(row.id);
																					initFacilityChange();
																				}}
																				className='dropdown-item'
																			>
																				Change Facility
																			</a>
																		</li>
																		{roles.includes(userData.role.name) && (
																			<li>
																				<a
																					style={{ cursor: 'pointer' }}
																					onClick={() => initDelete(row.id)}
																					className='dropdown-item'
																				>
																					Delete Enrollee
																				</a>
																			</li>
																		)}
																		<li>
																			<a
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					handleStatusDeactivate(row.id)
																				}
																				className='dropdown-item'
																			>
																				Deactivate Enrollee
																			</a>
																		</li>

																		{typeof row?.active_subs !== 'undefined' &&
																			row?.active_subs[0]?.is_family_plan ===
																				1 &&
																			row?.package?.name ===
																				'Formal Sector Health Plan' && (
																				<li>
																					<a
																						style={{ cursor: 'pointer' }}
																						onClick={() =>
																							initAddDependant(row.id)
																						}
																						className='dropdown-item'
																					>
																						Add Dependant
																					</a>
																				</li>
																			)}

																		{!row.plan_code && (
																			<>
																				<li>
																					<a
																						style={{ cursor: 'pointer' }}
																						onClick={(e) => {
																							e.preventDefault();

																							dependantVerificationMutation({
																								id: row.insurance_id,
																								status:
																									row.dependant_verification_status ===
																									'verified'
																										? 'unverified'
																										: 'verified',
																							});
																						}}
																						className='dropdown-item'
																					>
																						{row.dependant_verification_status ===
																						'verified'
																							? 'Unverify'
																							: 'Verify'}
																					</a>
																				</li>
																			</>
																		)}
																	</ul>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>

										<div className='row'>
											<div className='col-sm-12 col-md-5'>
												Showing {meta?.from} - {meta?.to} of {meta?.total}{' '}
												Results
											</div>
											<div className='col-sm-12 col-md-7'>
												<Paginate data={data} onPageChange={handlePageClick} />
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Details
					handleCloseModal={handleCloseModal}
					data={enrolleeDetails}
					loading={enrolleeLoading}
				/>
			</Modal>

			<Modal
				show={planModalState}
				onHide={handlePlanCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<Modal.Header closeButton>
					<Modal.Title>{planModalTitle}</Modal.Title>
				</Modal.Header>

				<PlanDetails
					handlePlanCloseModal={handlePlanCloseModal}
					enrolleeDetails={enrolleeDetails}
					queryName={queryName}
					query={query}
				/>
			</Modal>

			<Modal
				show={facilityChangeModalState}
				onHide={handleFacilityChangeCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				size='lg'
			>
				<InitiateFacilityChange
					data={enrolleeDetails}
					loading={enrolleeLoading}
					facilities={facilities}
					setFacilityChangeModalState={setFacilityChangeModalState}
				/>
			</Modal>

			<Modal
				show={msState}
				onHide={closeModal}
				animation={false}
				backdrop='static'
				keyboard={false}
				//size="sm"
			>
				<Modal.Header closeButton={toggleCloseBtn}>
					<Modal.Title>{msTitle}</Modal.Title>
				</Modal.Header>

				<ModalSearch
					searchValues={searchValues}
					setSearchValues={setSearchValues}
					closeModal={closeModal}
					onSearchSubmit={onSearchSubmit}
					onExportSubmit={onExportSubmit}
					exportData={exportData}
					exporting={exporting}
					exportReady={exportReady}
					resetExport={resetExport}
					lgas={lgas}
					packages={packages}
					searchType={searchType}
					mdas={mdas}
					facilities={facilities}
				/>
			</Modal>
		</>
	);
}
